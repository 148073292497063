const defaultBookmarks = {
    social: {
      name: "Social Media",
      bookmarks: [
        { id: "s1", title: "Facebook", link: "https://www.facebook.com", openPreference: "newTab", favicon: "https://www.facebook.com/favicon.ico" },
        { id: "s2", title: "Twitter", link: "https://www.twitter.com", openPreference: "newTab", favicon: "https://abs.twimg.com/favicons/twitter.ico" },
        { id: "s3", title: "LinkedIn", link: "https://www.linkedin.com", openPreference: "newTab", favicon: "https://static-exp1.licdn.com/sc/h/al2o9zrvru7aqj8e1x2rzsrca" },
        { id: "s4", title: "Instagram", link: "https://www.instagram.com", openPreference: "newTab", favicon: "https://www.instagram.com/static/images/ico/favicon.ico/36b3ee2d91ed.ico" },
        { id: "s5", title: "TikTok", link: "https://www.tiktok.com", openPreference: "newTab", favicon: "https://s16.tiktokcdn.com/musical/resource/mtact/static/pwa/icon_128x128.png" }
      ]
    },
    news: {
      name: "News",
      bookmarks: [
        { id: "n1", title: "CNN", link: "https://www.cnn.com", openPreference: "newTab", favicon: "https://www.cnn.com/favicon.ico" },
        { id: "n2", title: "BBC", link: "https://www.bbc.com", openPreference: "newTab", favicon: "https://nav.files.bbci.co.uk/orbit-webcore/production/f5122c4a6c1fada1e1e84d2a3f7e5544/images/favicon.ico" },
        { id: "n3", title: "The New York Times", link: "https://www.nytimes.com", openPreference: "newTab", favicon: "https://www.nytimes.com/favicon.ico" },
        { id: "n4", title: "Reuters", link: "https://www.reuters.com", openPreference: "newTab", favicon: "https://www.reuters.com/favicon.ico" },
        { id: "n5", title: "The Guardian", link: "https://www.theguardian.com", openPreference: "newTab", favicon: "https://assets.guim.co.uk/images/favicons/46bd2faa1ab438684a6d4528a655a8bd/32x32.ico" }
      ]
    },
    entertainment: {
      name: "Entertainment",
      bookmarks: [
        { id: "e1", title: "YouTube", link: "https://www.youtube.com", openPreference: "newTab", favicon: "https://www.youtube.com/favicon.ico" },
        { id: "e2", title: "Netflix", link: "https://www.netflix.com", openPreference: "newTab", favicon: "https://assets.nflxext.com/us/ffe/siteui/common/icons/nficon2016.ico" },
        { id: "e3", title: "Spotify", link: "https://www.spotify.com", openPreference: "newTab", favicon: "https://www.scdn.co/i/_global/favicon.png" },
        { id: "e4", title: "IMDb", link: "https://www.imdb.com", openPreference: "newTab", favicon: "https://m.media-amazon.com/images/G/01/imdb/images-ANDW73HA/favicon_desktop_32x32._CB1582158068_.png" },
        { id: "e5", title: "Twitch", link: "https://www.twitch.tv", openPreference: "newTab", favicon: "https://static.twitchcdn.net/assets/favicon-32-e29e246c157142c94346.png" }
      ]
    },
    shopping: {
      name: "Shopping",
      bookmarks: [
        { id: "sh1", title: "Amazon", link: "https://www.amazon.com", openPreference: "newTab", favicon: "https://www.amazon.com/favicon.ico" },
        { id: "sh2", title: "eBay", link: "https://www.ebay.com", openPreference: "newTab", favicon: "https://pages.ebay.com/favicon.ico" },
        { id: "sh3", title: "Etsy", link: "https://www.etsy.com", openPreference: "newTab", favicon: "https://www.etsy.com/favicon.ico" },
        { id: "sh4", title: "Walmart", link: "https://www.walmart.com", openPreference: "newTab", favicon: "https://www.walmart.com/favicon.ico" },
        { id: "sh5", title: "AliExpress", link: "https://www.aliexpress.com", openPreference: "newTab", favicon: "https://ae01.alicdn.com/images/eng/wholesale/icon/aliexpress.ico" }
      ]
    },
    tech: {
      name: "Technology",
      bookmarks: [
        { id: "t1", title: "TechCrunch", link: "https://techcrunch.com", openPreference: "newTab", favicon: "https://techcrunch.com/wp-content/uploads/2015/02/cropped-cropped-favicon-gradient.png?w=32" },
        { id: "t2", title: "Wired", link: "https://www.wired.com", openPreference: "newTab", favicon: "https://www.wired.com/favicon.ico" },
        { id: "t3", title: "The Verge", link: "https://www.theverge.com", openPreference: "newTab", favicon: "https://cdn.vox-cdn.com/uploads/chorus_asset/file/7395359/favicon-32x32.0.png" },
        { id: "t4", title: "Ars Technica", link: "https://arstechnica.com", openPreference: "newTab", favicon: "https://cdn.arstechnica.net/favicon.ico" },
        { id: "t5", title: "CNET", link: "https://www.cnet.com", openPreference: "newTab", favicon: "https://www.cnet.com/favicon.ico" }
      ]
    },
    productivity: {
      name: "Productivity",
      bookmarks: [
        { id: "p1", title: "Google Drive", link: "https://drive.google.com", openPreference: "newTab", favicon: "https://ssl.gstatic.com/docs/doclist/images/drive_2022q3_32dp.png" },
        { id: "p2", title: "Trello", link: "https://trello.com", openPreference: "newTab", favicon: "https://trello.com/favicon.ico" },
        { id: "p3", title: "Notion", link: "https://www.notion.so", openPreference: "newTab", favicon: "https://www.notion.so/front-static/favicon.ico" },
        { id: "p4", title: "Evernote", link: "https://evernote.com", openPreference: "newTab", favicon: "https://evernote.com/img/favicon.ico" },
        { id: "p5", title: "Asana", link: "https://asana.com", openPreference: "newTab", favicon: "https://d1gwm4cf8hecp4.cloudfront.net/images/favicons/favicon-32x32.png" }
      ]
    },
    education: {
      name: "Education",
      bookmarks: [
        { id: "ed1", title: "Coursera", link: "https://www.coursera.org", openPreference: "newTab", favicon: "https://d3njjcbhbojbot.cloudfront.net/web/images/favicons/favicon-v2-32x32.png" },
        { id: "ed2", title: "edX", link: "https://www.edx.org", openPreference: "newTab", favicon: "https://www.edx.org/favicon.ico" },
        { id: "ed3", title: "Khan Academy", link: "https://www.khanacademy.org", openPreference: "newTab", favicon: "https://www.khanacademy.org/favicon.ico" },
        { id: "ed4", title: "Udemy", link: "https://www.udemy.com", openPreference: "newTab", favicon: "https://www.udemy.com/favicon.ico" },
        { id: "ed5", title: "Duolingo", link: "https://www.duolingo.com", openPreference: "newTab", favicon: "https://d35aaqx5ub95lt.cloudfront.net/favicon.ico" }
      ]
    },
    finance: {
      name: "Finance",
      bookmarks: [
        { id: "f1", title: "Yahoo Finance", link: "https://finance.yahoo.com", openPreference: "newTab", favicon: "https://s.yimg.com/cv/apiv2/default/20201027/favicon.ico" },
        { id: "f2", title: "Bloomberg", link: "https://www.bloomberg.com", openPreference: "newTab", favicon: "https://assets.bwbx.io/s3/javelin/public/javelin/images/favicon-black-63fe5249d3.png" },
        { id: "f3", title: "Investopedia", link: "https://www.investopedia.com", openPreference: "newTab", favicon: "https://www.investopedia.com/favicon.ico" },
        { id: "f4", title: "MarketWatch", link: "https://www.marketwatch.com", openPreference: "newTab", favicon: "https://mw3.wsj.net/mw5/content/images/favicons/apple-touch-icon.png" },
        { id: "f5", title: "CNBC", link: "https://www.cnbc.com", openPreference: "newTab", favicon: "https://www.cnbc.com/favicon.ico" }
      ]
    },
    travel: {
      name: "Travel",
      bookmarks: [
        { id: "tr1", title: "Booking.com", link: "https://www.booking.com", openPreference: "newTab", favicon: "https://cf.bstatic.com/static/img/favicon/ebc77706da3aae4aee7b05dadf182390f0d26d11.ico" },
        { id: "tr2", title: "Airbnb", link: "https://www.airbnb.com", openPreference: "newTab", favicon: "https://a0.muscache.com/airbnb/static/logotype_favicon-21cc8e6c6a2cca43f061d2dcabdf6e58.ico" },
        { id: "tr3", title: "TripAdvisor", link: "https://www.tripadvisor.com", openPreference: "newTab", favicon: "https://static.tacdn.com/favicon.ico" },
        { id: "tr4", title: "Expedia", link: "https://www.expedia.com", openPreference: "newTab", favicon: "https://www.expedia.com/_dms/header/logo.ico?locale=en_US&siteid=1" },
        { id: "tr5", title: "Skyscanner", link: "https://www.skyscanner.com", openPreference: "newTab", favicon: "https://www.skyscanner.com/favicon.ico" }
      ]
    },
    sports: {
      name: "Sports",
      bookmarks: [
        { id: "sp1", title: "ESPN", link: "https://www.espn.com", openPreference: "newTab", favicon: "https://a.espncdn.com/favicon.ico" },
        { id: "sp2", title: "BBC Sport", link: "https://www.bbc.com/sport", openPreference: "newTab", favicon: "https://static.files.bbci.co.uk/orbit/1e34e8a7bdb4c3b6f0f8b2c3c8c3f0b3/img/blq-orbit-blocks_grey.ico" },
        { id: "sp3", title: "NBA", link: "https://www.nba.com", openPreference: "newTab", favicon: "https://www.nba.com/favicon.ico" },
        { id: "sp4", title: "NFL", link: "https://www.nfl.com", openPreference: "newTab", favicon: "https://static.www.nfl.com/image/upload/v1554321393/league/apps/touch-icon-ipad.jpg" },
        { id: "sp5", title: "FIFA", link: "https://www.fifa.com", openPreference: "newTab", favicon: "https://digitalhub.fifa.com/transform/3a170b69-b0b5-4d0c-bca0-85880a60ea1a/World-Cup-logo-landscape-on-dark?io=transform:fill&quality=75" }
      ]
    }
  };
  
  export default defaultBookmarks;
  