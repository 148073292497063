import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  CircularProgress, 
  Typography,
  ThemeProvider,
  createTheme,
  Button
} from '@mui/material';
import { FormControlLabel, FormControl, RadioGroup, Radio } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import config from '../config';

function Costco() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterOption, setFilterOption] = useState('all');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetch(`${config.cost.url}/purchases/price-adjustments`)
      .then(response => response.json())
      .then(data => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (data?.allProducts) {
      if (filterOption === 'adjustment') {
        setFilteredData(data?.allProducts.filter(item => {
          if (item.go_price_adjustment === 1 || item.canUseCoupon) {
            return true;
          }
          for (const trans of item.transactions) {
            if (trans.unit === 0 && trans.amount < 0) {
              return true;
            }
          }
          return false;          
        }));
      } else if (filterOption === 'coupon') {
        setFilteredData(data?.allProducts.filter(item => {
          for (const trans of item.transactions) {
            if (trans.itemDescription01.startsWith('/')) {
              return true;
            }
          }
          return false;
        }));
      }else {
        setFilteredData(data?.allProducts);
      }
    }
  }, [filterOption, data]);

  if (loading) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  const theme = createTheme({
    typography: {
      h4: {
        fontSize: '1.5rem',
        fontWeight: 600,
        color: '#1976d2',
        marginTop: '20px',
      },
    },
  });

  const handleUploadClick = () => {
    navigate('./upload');
  };

  return (
    <Container>
      <ThemeProvider theme={theme}>
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between', 
          marginBottom: '1rem' 
        }}>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography variant="h4" style={{ marginRight: '1rem' }}>Cost$$ Purchases Records Share</Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="filter-options"
                name="filter-options"
                value={filterOption}
                onChange={(e) => setFilterOption(e.target.value)}
              >
                <FormControlLabel value="all" control={<Radio />} label="All" />
                <FormControlLabel value="coupon" control={<Radio />} label="Coupon" />
                <FormControlLabel value="adjustment" control={<Radio />} label="Possible products for price adjustment" />
              </RadioGroup>
              {filterOption === 'adjustment' && (
                <Typography variant="caption" color="textSecondary" style={{ marginTop: '8px' }}>
                  Note: Price adjustments are eligible for items purchased within 30 days from the same warehouse, excluding Deli products.
                </Typography>
              )}
            </FormControl>
          </div>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleUploadClick}
            style={{ marginTop: '10px'}}
          >
            Upload Records
          </Button>
        </div>
      </ThemeProvider>
      {filteredData.length > 0 && (
        <Paper elevation={1} style={{ padding: '5px', marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" style={{ marginRight: '10px' }}>Latest transaction info:</Typography>
          <Typography style={{ marginRight: '10px' }}>
            <i>Date: </i><u>{new Date(Math.max(...filteredData.flatMap(item => item.transactions.map(t => new Date(t.transactionDateTime))))).toLocaleDateString()}</u>
          </Typography>
          <Typography style={{ marginRight: '10px' }}>
            <i>Warehouse: </i><u>{filteredData.flatMap(item => item.transactions).sort((a, b) => new Date(b.transactionDateTime) - new Date(a.transactionDateTime))[0].warehouseName}</u>
          </Typography>
          <Typography>
            <i>Shopper: </i><u>{filteredData.flatMap(item => item.transactions).sort((a, b) => new Date(b.transactionDateTime) - new Date(a.transactionDateTime))[0].nickname.toUpperCase()}</u>
          </Typography>
        </Paper>
      )}
      <TableContainer component={Paper} elevation={3}>
        <Table sx={{ minWidth: 650, '& td, & th': { border: '1px solid #e0e0e0' } }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Item Info (only list within 35 days)</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Transaction Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Warehouse</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Unit</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Amount</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Unit Price</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Shopper</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((item) => (
              <React.Fragment key={item.itemNumber}>
                <TableRow>
                  <TableCell 
                    rowSpan={item.transactions.length + 1}
                    sx={{
                        backgroundColor: item.go_price_adjustment ? '#ffebee' : '#f5f5f5',
                        borderRight: '1px solid #e0e0e0',
                    }}
                    >
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        {item.transactions[0].itemDescription01} - {item.transactions[0].itemDescription02}
                    </Typography>
                    <Typography variant="subtitle2">Item Number: {item.itemNumber}</Typography>
                    {item.go_price_adjustment === 1 && (
                      <Typography variant="body2">Price Adjustable? {item.go_price_adjustment ? 'Yes' : 'No'}</Typography>
                    )}                    
                    {item.lowestUnitPrice && (
                      <Typography variant="body2">Lowest Unit Price: ${item.lowestUnitPrice?.toFixed(2)}</Typography>
                    )}
                    {item.lowestUnitPriceDate && (
                      <Typography variant="body2">Lowest Price Date: {item.lowestUnitPriceDate.substring(0,10)}</Typography>
                    )}                    
                  </TableCell>
                </TableRow>
                {item.transactions.map((transaction, index) => (
                  <TableRow
                    key={`${item.itemNumber}-${index}`}
                    sx={{
                      backgroundColor: transaction.canAdjust ? '#ffebee' : '#f5f5f5'
                    }}
                  >
                    <TableCell>
                      {transaction.itemDescription01}
                      {transaction.itemDescription01.startsWith('/') && (
                        <Typography variant="caption" color="text.secondary" style={{ marginLeft: '2px' }}>
                          (coupon)
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                        {transaction.transactionDateTime}
                        <br />
                        <Typography variant="caption">
                            {transaction.transactionBarcode}
                        </Typography>
                    </TableCell>
                    <TableCell>{transaction.warehouseName}</TableCell>
                    <TableCell>{transaction.unit}</TableCell>
                    <TableCell>${transaction.amount.toFixed(2)}</TableCell>
                    <TableCell>
                      ${transaction.unitPrice?.toFixed(2)}
                      {transaction.amount < 0 && !transaction.itemDescription01.startsWith('/') && (
                        <Typography variant="caption" color="text.secondary" style={{ marginLeft: '2px' }}>
                          (refund)
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell style={{ textTransform: 'uppercase'}}>{transaction.nickname}</TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default Costco;