const websites = [
    // Top AI Websites
    {
        url: 'https://www.anthropic.com',
        favicon: 'https://www.anthropic.com/favicon.ico',
        name: 'Anthropic',
        description: 'Leading AI research and deployment company',
        categories: ['AI'],
        estimatedMonthlyVisits: 721900000
    },
    {
        url: 'https://www.coreweave.com',
        favicon: 'https://www.coreweave.com/favicon.ico',
        name: 'CoreWeave',
        description: 'AI infrastructure and cloud services',
        categories: ['AI'],
        estimatedMonthlyVisits: 30000000
    },
    {
        url: 'https://huggingface.co',
        favicon: 'https://huggingface.co/favicon.ico',
        name: 'Hugging Face',
        description: 'AI model hub and collaboration platform',
        categories: ['AI'],
        estimatedMonthlyVisits: 85200000
    },
    {
        url: 'https://www.shieldai.com',
        favicon: 'https://www.shieldai.com/favicon.ico',
        name: 'Shield AI',
        description: 'AI-powered defense systems',
        categories: ['AI'],
        estimatedMonthlyVisits: 320000000
    },
    {
        url: 'https://www.character.ai',
        favicon: 'https://www.character.ai/favicon.ico',
        name: 'Character.AI',
        description: 'AI-powered character interactions',
        categories: ['AI'],
        estimatedMonthlyVisits: 14000000
    },
    {
        url: 'https://writer.com',
        favicon: 'https://writer.com/favicon.ico',
        name: 'Writer',
        description: 'AI writing assistant for enterprises',
        categories: ['AI'],
        estimatedMonthlyVisits: 245200000
    },
    {
        url: 'https://runwayml.com',
        favicon: 'https://runwayml.com/favicon.ico',
        name: 'Runway ML',
        description: 'AI-powered creative tools',
        categories: ['AI'],
        estimatedMonthlyVisits: 26800000
    },
    {
        url: 'https://cohere.ai',
        favicon: 'https://cohere.ai/favicon.ico',
        name: 'Cohere',
        description: 'Large language models for businesses',
        categories: ['AI'],
        estimatedMonthlyVisits: 33900000
    },
    {
        url: 'https://www.typeface.ai',
        favicon: 'https://www.typeface.ai/favicon.ico',
        name: 'Typeface',
        description: 'AI-powered content creation platform',
        categories: ['AI'],
        estimatedMonthlyVisits: 15500000
    },
    {
        url: 'https://www.pinecone.io',
        favicon: 'https://www.pinecone.io/favicon.ico',
        name: 'Pinecone',
        description: 'Vector database for machine learning',
        categories: ['AI'],
        estimatedMonthlyVisits: 26600000
    },
    {
        url: 'https://www.inworld.ai',
        favicon: 'https://www.inworld.ai/favicon.ico',
        name: 'Inworld AI',
        description: 'AI characters for games and virtual worlds',
        categories: ['AI'],
        estimatedMonthlyVisits: 15800000
    },
    {
        url: 'https://www.imbue.com',
        favicon: 'https://www.imbue.com/favicon.ico',
        name: 'Imbue',
        description: 'AI research and development',
        categories: ['AI'],
        estimatedMonthlyVisits: 1600000
    },
    {
        url: 'https://pryon.com',
        favicon: 'https://pryon.com/favicon.ico',
        name: 'Pryon',
        description: 'AI-powered knowledge management',
        categories: ['AI'],
        estimatedMonthlyVisits: 24200000
    },
    {
        url: 'https://www.together.ai',
        favicon: 'https://www.together.ai/favicon.ico',
        name: 'Together AI',
        description: 'Collaborative AI research and development',
        categories: ['AI'],
        estimatedMonthlyVisits: 8600000
    },
    {
        url: 'https://www.adept.ai',
        favicon: 'https://www.adept.ai/favicon.ico',
        name: 'Adept',
        description: 'AI models that can use software tools',
        categories: ['AI'],
        estimatedMonthlyVisits: 11100000
    },
    {
        url: 'https://elevenlabs.io',
        favicon: 'https://elevenlabs.io/favicon.ico',
        name: 'ElevenLabs',
        description: 'AI-powered voice synthesis',
        categories: ['AI'],
        estimatedMonthlyVisits: 12600000
    },
    {
        url: 'https://www.synthesia.io',
        favicon: 'https://www.synthesia.io/favicon.ico',
        name: 'Synthesia',
        description: 'AI video generation platform',
        categories: ['AI'],
        estimatedMonthlyVisits: 79600000
    },
    {
        url: 'https://www.unlearn.ai',
        favicon: 'https://www.unlearn.ai/favicon.ico',
        name: 'Unlearn.AI',
        description: 'AI for accelerating clinical trials',
        categories: ['AI'],
        estimatedMonthlyVisits: 12900000
    },
    {
        url: 'https://www.ai21.com',
        favicon: 'https://www.ai21.com/favicon.ico',
        name: 'AI21',
        description: 'Advanced language AI solutions',
        categories: ['AI'],
        estimatedMonthlyVisits: 42100000
    },
    {
        url: 'https://tome.app',
        favicon: 'https://tome.app/favicon.ico',
        name: 'Tome',
        description: 'AI-powered storytelling platform',
        categories: ['AI'],
        estimatedMonthlyVisits: 11900000
    },
  
    // Top Web3 Technologies
        {
          url: 'https://crypto.com',
          favicon: 'https://crypto.com/favicon.ico',
          name: 'Crypto.com',
          description: 'Leading cryptocurrency platform and exchange',
          categories: ['Web3'],
          estimatedMonthlyVisits: 27000000
        },
        {
          url: 'https://www.binance.com',
          favicon: 'https://www.binance.com/favicon.ico',
          name: 'Binance',
          description: 'Major cryptocurrency exchange and blockchain ecosystem',
          categories: ['Web3'],
          estimatedMonthlyVisits: 95000000
        },
        {
          url: 'https://opensea.io',
          favicon: 'https://opensea.io/favicon.ico',
          name: 'OpenSea',
          description: 'Prominent NFT marketplace',
          categories: ['Web3'],
          estimatedMonthlyVisits: 30000000
        },
        {
          url: 'https://www.chainalysis.com',
          favicon: 'https://www.chainalysis.com/favicon.ico',
          name: 'Chainanalysis',
          description: 'Blockchain analysis and compliance solutions provider',
          categories: ['Web3'],
          estimatedMonthlyVisits: 500000
        },
        {
          url: 'https://ripple.com',
          favicon: 'https://ripple.com/favicon.ico',
          name: 'Ripple',
          description: 'Digital payment protocol and cryptocurrency',
          categories: ['Web3'],
          estimatedMonthlyVisits: 3000000
        },
        {
          url: 'https://consensys.net',
          favicon: 'https://consensys.net/favicon.ico',
          name: 'Consensys',
          description: 'Ethereum blockchain software company',
          categories: ['Web3'],
          estimatedMonthlyVisits: 1500000
        },
        {
          url: 'https://www.gemini.com',
          favicon: 'https://www.gemini.com/favicon.ico',
          name: 'Gemini',
          description: 'Regulated cryptocurrency exchange and custodian',
          categories: ['Web3'],
          estimatedMonthlyVisits: 8000000
        },
        {
          url: 'https://polygon.technology',
          favicon: 'https://polygon.technology/favicon.ico',
          name: 'Polygon',
          description: 'Ethereum scaling platform for Web3 applications',
          categories: ['Web3'],
          estimatedMonthlyVisits: 2500000
        },
        {
          url: 'https://www.moonpay.com',
          favicon: 'https://www.moonpay.com/favicon.ico',
          name: 'MoonPay',
          description: 'Fiat-to-crypto payment infrastructure provider',
          categories: ['Web3'],
          estimatedMonthlyVisits: 1000000
        },
        {
          url: 'https://sorare.com',
          favicon: 'https://sorare.com/favicon.ico',
          name: 'Sorare',
          description: 'Fantasy soccer game using NFTs on Ethereum blockchain',
          categories: ['Web3'],
          estimatedMonthlyVisits: 5000000
        },      
  
    // Top Blockchains
        {
          url: 'https://ethereum.org',
          favicon: 'https://ethereum.org/favicon.ico',
          name: 'Ethereum',
          description: 'Leading smart contract platform',
          categories: ['Blockchain'],
          estimatedMonthlyVisits: 20000000
        },
        {
          url: 'https://www.binance.org',
          favicon: 'https://www.binance.org/favicon.ico',
          name: 'Binance Smart Chain',
          description: 'Fast and low-cost smart contract platform',
          categories: ['Blockchain'],
          estimatedMonthlyVisits: 15000000
        },
        {
          url: 'https://solana.com',
          favicon: 'https://solana.com/favicon.ico',
          name: 'Solana',
          description: 'High-performance blockchain for dApps',
          categories: ['Blockchain'],
          estimatedMonthlyVisits: 12000000
        },
        {
          url: 'https://polkadot.network',
          favicon: 'https://polkadot.network/favicon.ico',
          name: 'Polkadot',
          description: 'Interoperable blockchain network',
          categories: ['Blockchain'],
          estimatedMonthlyVisits: 8000000
        },
        {
          url: 'https://www.avax.network',
          favicon: 'https://www.avax.network/favicon.ico',
          name: 'Avalanche',
          description: 'Fast, low-cost, and eco-friendly blockchain',
          categories: ['Blockchain'],
          estimatedMonthlyVisits: 7000000
        },
        {
          url: 'https://cardano.org',
          favicon: 'https://cardano.org/favicon.ico',
          name: 'Cardano',
          description: 'Sustainable blockchain platform',
          categories: ['Blockchain'],
          estimatedMonthlyVisits: 6500000
        },
        {
          url: 'https://tron.network',
          favicon: 'https://tron.network/favicon.ico',
          name: 'Tron',
          description: 'Decentralized entertainment ecosystem',
          categories: ['Blockchain'],
          estimatedMonthlyVisits: 6000000
        },
        {
          url: 'https://www.stellar.org',
          favicon: 'https://www.stellar.org/favicon.ico',
          name: 'Stellar',
          description: 'Fast, low-cost blockchain for payments',
          categories: ['Blockchain'],
          estimatedMonthlyVisits: 5500000
        },
        {
          url: 'https://eos.io',
          favicon: 'https://eos.io/favicon.ico',
          name: 'EOS',
          description: 'Blockchain for decentralized applications',
          categories: ['Blockchain'],
          estimatedMonthlyVisits: 5000000
        },
        {
          url: 'https://tezos.com',
          favicon: 'https://tezos.com/favicon.ico',
          name: 'Tezos',
          description: 'Self-upgrading blockchain network',
          categories: ['Blockchain'],
          estimatedMonthlyVisits: 4500000
        },
        {
          url: 'https://www.algorand.com',
          favicon: 'https://www.algorand.com/favicon.ico',
          name: 'Algorand',
          description: 'Pure proof-of-stake blockchain',
          categories: ['Blockchain'],
          estimatedMonthlyVisits: 4000000
        },
        {
          url: 'https://cosmos.network',
          favicon: 'https://cosmos.network/favicon.ico',
          name: 'Cosmos',
          description: 'Ecosystem of interoperable blockchains',
          categories: ['Blockchain'],
          estimatedMonthlyVisits: 3500000
        },
        {
          url: 'https://neo.org',
          favicon: 'https://neo.org/favicon.ico',
          name: 'NEO',
          description: 'Open network for smart economy',
          categories: ['Blockchain'],
          estimatedMonthlyVisits: 3000000
        },
        {
          url: 'https://www.hyperledger.org/use/fabric',
          favicon: 'https://www.hyperledger.org/favicon.ico',
          name: 'Hyperledger Fabric',
          description: 'Modular blockchain for business',
          categories: ['Blockchain'],
          estimatedMonthlyVisits: 2500000
        },
        {
          url: 'https://www.corda.net',
          favicon: 'https://www.corda.net/favicon.ico',
          name: 'Corda',
          description: 'Blockchain for financial services',
          categories: ['Blockchain'],
          estimatedMonthlyVisits: 2000000
        },

    // Top Quantum Computing
    {
      url: 'https://www.ibm.com/quantum',
      favicon: 'https://www.ibm.com/favicon.ico',
      name: 'IBM Quantum',
      description: 'Leading quantum computing research and development',
      categories: ['Quantum Computing'],
      estimatedMonthlyVisits: 10000000
    },
    {
      url: 'https://quantumai.google',
      favicon: 'https://quantumai.google/favicon.ico',
      name: 'Google Quantum AI',
      description: 'Quantum computing research and applications',
      categories: ['Quantum Computing'],
      estimatedMonthlyVisits: 8000000
    },
    {
      url: 'https://www.rigetti.com',
      favicon: 'https://www.rigetti.com/favicon.ico',
      name: 'Rigetti Computing',
      description: 'Full-stack quantum computing company',
      categories: ['Quantum Computing'],
      estimatedMonthlyVisits: 5000000
    },
  
    // New Websites
    {
      url: 'https://www.aptos.com',
      favicon: 'https://www.aptos.com/favicon.ico',
      name: 'Aptos',
      description: 'Scalable blockchain solution',
      categories: ['Blockchain', 'Web3'],
      estimatedMonthlyVisits: 2000000
    },
    {
      url: 'https://www.thetanetwork.com',
      favicon: 'https://www.thetanetwork.com/favicon.ico',
      name: 'Theta Network',
      description: 'Decentralized video streaming network',
      categories: ['Blockchain', 'Web3'],
      estimatedMonthlyVisits: 1500000
    },
  
    // Top Social Media
        {
          url: 'https://www.facebook.com',
          favicon: 'https://www.facebook.com/favicon.ico',
          name: 'Facebook',
          description: 'Largest social media platform globally',
          categories: ['Social Media'],
          estimatedMonthlyVisits: 2960000000
        },
        {
          url: 'https://www.youtube.com',
          favicon: 'https://www.youtube.com/favicon.ico',
          name: 'YouTube',
          description: 'Video sharing and streaming platform',
          categories: ['Social Media'],
          estimatedMonthlyVisits: 2510000000
        },
        {
          url: 'https://www.whatsapp.com',
          favicon: 'https://www.whatsapp.com/favicon.ico',
          name: 'WhatsApp',
          description: 'Messaging and communication platform',
          categories: ['Social Media'],
          estimatedMonthlyVisits: 2000000000
        },
        {
          url: 'https://www.instagram.com',
          favicon: 'https://www.instagram.com/favicon.ico',
          name: 'Instagram',
          description: 'Photo and video sharing social network',
          categories: ['Social Media'],
          estimatedMonthlyVisits: 2000000000
        },
        {
          url: 'https://www.wechat.com',
          favicon: 'https://www.wechat.com/favicon.ico',
          name: 'WeChat',
          description: 'Multi-purpose messaging and social media platform',
          categories: ['Social Media'],
          estimatedMonthlyVisits: 1310000000
        },
        {
          url: 'https://www.tiktok.com',
          favicon: 'https://www.tiktok.com/favicon.ico',
          name: 'TikTok',
          description: 'Short-form video sharing platform',
          categories: ['Social Media'],
          estimatedMonthlyVisits: 1050000000
        },
        {
          url: 'https://www.messenger.com',
          favicon: 'https://www.messenger.com/favicon.ico',
          name: 'Facebook Messenger',
          description: 'Instant messaging platform',
          categories: ['Social Media'],
          estimatedMonthlyVisits: 931000000
        },
        {
          url: 'https://www.douyin.com',
          favicon: 'https://www.douyin.com/favicon.ico',
          name: 'Douyin',
          description: 'Chinese short-form video platform',
          categories: ['Social Media'],
          estimatedMonthlyVisits: 715000000
        },
        {
          url: 'https://telegram.org',
          favicon: 'https://telegram.org/favicon.ico',
          name: 'Telegram',
          description: 'Cloud-based instant messaging platform',
          categories: ['Social Media'],
          estimatedMonthlyVisits: 700000000
        },
        {
          url: 'https://www.snapchat.com',
          favicon: 'https://www.snapchat.com/favicon.ico',
          name: 'Snapchat',
          description: 'Multimedia messaging app',
          categories: ['Social Media'],
          estimatedMonthlyVisits: 635000000
        },
        
        // Top News
            {
              url: 'https://www.justanswer.com',
              favicon: 'https://www.justanswer.com/favicon.ico',
              name: 'Just Answer',
              description: 'Gig economy platform for expert consultations',
              categories: ['News'],
              estimatedMonthlyVisits: 21000000
            },
            {
              url: 'https://www.monarchmoney.com',
              favicon: 'https://www.monarchmoney.com/favicon.ico',
              name: 'Monarch Money',
              description: 'Personal finance management tools',
              categories: ['News'],
              estimatedMonthlyVisits: 543000000
            },
            {
              url: 'https://www.tapo.com',
              favicon: 'https://www.tapo.com/favicon.ico',
              name: 'Tapo',
              description: 'Smart home and security devices',
              categories: ['News'],
              estimatedMonthlyVisits: 211000000
            },
            {
              url: 'https://gbnews.com',
              favicon: 'https://gbnews.com/favicon.ico',
              name: 'GBN',
              description: 'British news, right-leaning',
              categories: ['News'],
              estimatedMonthlyVisits: 402000000
            },
            {
              url: 'https://ground.news',
              favicon: 'https://ground.news/favicon.ico',
              name: 'Ground News',
              description: 'News comparison and media bias filter',
              categories: ['News'],
              estimatedMonthlyVisits: 63000000
            }         
  ];
  
  export default websites;
  