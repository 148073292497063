import React from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";

const CategoryDialog = ({
    isCategoryModalOpen,
    setIsCategoryModalOpen,
    newCategoryTitle,
    setNewCategoryTitle,
    handleAddCategory
}) => {
  return (
    <>
      <Dialog open={isCategoryModalOpen} onClose={() => setIsCategoryModalOpen(false)}>
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            fullWidth
            value={newCategoryTitle}
            onChange={(e) => setNewCategoryTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCategoryModalOpen(false)}>Cancel</Button>
          <Button onClick={handleAddCategory} color="primary">Add</Button>
        </DialogActions>
      </Dialog>    
    </>
  );
};

export default CategoryDialog;
