import React from 'react';
import { TextField, Box } from '@mui/material';

function RecipientList({ recipients, setRecipients }) {
  const handleRecipientsChange = (event) => {
    const recipientList = event.target.value.split('\n').filter(recipient => recipient.trim() !== '');
    setRecipients(recipientList);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const recipientList = event.target.value.split('\n').filter(recipient => recipient.trim() !== '');
      recipientList.push('');
      setRecipients(recipientList);
    }
  };


  return (
    <Box>
      <TextField
        fullWidth
        multiline
        rows={6}
        value={recipients.join('\n')}
        onChange={handleRecipientsChange}
        onKeyPress={handleKeyPress}
        label="Recipients (one per line)"
        placeholder="Enter recipients (one per line)"
        variant="outlined"
        size="small"
      />
    </Box>
  );
}

export default RecipientList;
