import React from "react";

const BookmarkViewer = ({ url }) => {
  return (
    <iframe
      src={url}
      title="Bookmark Viewer"
      style={{ width: "100%", height: "500px", border: "none" }}
    ></iframe>
  );
};

export default BookmarkViewer;
