import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import QrCodeIcon from '@mui/icons-material/QrCode';
import logo from '../logo512.png';

const Header = ({ onQRCodeClick }) => (
  <AppBar position="static">
    <Toolbar>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Box display="flex" alignItems="center">
          <Link to="/">
            <img src={logo} alt="Logo" style={{ height: 80, marginRight: 16 }} />
          </Link>          
          <Typography variant="h5" component="div" className="app-title">
            <i>Cultivating Learning by AI Generated Data</i>
          </Typography>
        </Box>
        <IconButton color="inherit" onClick={onQRCodeClick} aria-label="QR Code">
          <QrCodeIcon />
        </IconButton>
      </Box>
    </Toolbar>
  </AppBar>
);

export default Header;