import React from "react";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/Folder';
import LinkIcon from '@mui/icons-material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const BookmarkTree = ({ bookmarks, onSelect, onEdit }) => {
  const renderTree = (node) => (
    node && <TreeItem
      key={node.id}
      itemId={node.id.toString()}
      label={
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            {node.children ? (
              <FolderIcon color="action" style={{ marginRight: 2, flexShrink: 0 }} />
            ) : node.openPreference === 'bookmarkViewer' ? (
              <LinkIcon color="action" style={{ marginRight: 2, flexShrink: 0 }} />
            ) : (
              <OpenInNewIcon color="action" style={{ marginRight: 2, flexShrink: 0 }} />
            )}
            <span style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>{node.title}</span>
          </div>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(node);
            }}
            style={{ flexShrink: 0 }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </div>
      }
    >
      {Array.isArray(node.children)
        ? node.children.map((child) => renderTree(child))
        : null}
    </TreeItem>
  );

  return (
    <SimpleTreeView
      onSelectedItemsChange={(event, itemIds) => {
        if (itemIds && itemIds.length > 0) {
          onSelect(itemIds);
        }
      }}
      multiSelect={false}
    >
      {bookmarks && bookmarks.length > 0 ? (
        bookmarks.map((bookmark) => renderTree(bookmark))
      ) : (
        <TreeItem itemId="empty" label="No bookmarks" />
      )}
    </SimpleTreeView>
  );
};

export default BookmarkTree;
