import React, { useState } from 'react';
import { ThemeProvider, createTheme, CssBaseline, Box } from '@mui/material';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import QRCodeModal from './components/QRCodeModal';
import Costco from './costco/Costco';
import bookTheme from './themes/theme';
import Bookmark from './bookmark/Bookmark';
import UploadReceipts from './costco/UploadReceipts';
import HolidayCardGenerator from './holiday/HolidayCardGenerator';
import qrCodeProfound from './qrcode-profoundwords.png';
import './App.css';
import PopularWebsites from './websites/PopularWebsites';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

function App() {
  const [qrCodeOpen, setQrCodeOpen] = useState(false);
  const [currentQrCode, setCurrentQrCode] = useState('');
  const [currentQrCodeTitle, setCurrentQrCodeTitle] = useState('');
  const [currentQrCodeDescription, setCurrentQrCodeDescription] = useState('');
  const [currentQrCodeLink, setCurrentQrCodeLink] = useState('');

  const handleQrCodeOpen = (qrCodeData) => {
    setCurrentQrCode(qrCodeData.qrcode);
    setCurrentQrCodeTitle(qrCodeData.title);
    setCurrentQrCodeDescription(qrCodeData.description);
    setCurrentQrCodeLink(qrCodeData.link);
    setQrCodeOpen(true);
  };

  const handleQrCodeClose = () => {
    setQrCodeOpen(false);
  };

  const blocks = [
    { title: 'Minecraft Block Words', description: 'All Blocks! ▯_▯', image: 'images/minecraft.png?v=1', link: 'https://minecraft.profoundwords.us/', qrcode: 'images/qr_minecraft.png?v=1' },
    { title: 'Financial Phrases', description: 'Empowering Your Financial Vocabulary', image: 'images/bloomberg.png?v=1', link: 'https://bloomberg.profoundwords.us/', qrcode: 'images/qr_bloomberg.png?v=1' },
    // { title: 'President Trump\'s Phrases', description: 'History is written by winners', image: 'images/trump.png?v=1', link: 'https://trump.profoundwords.us/', qrcode: 'images/qr_trump.png?v=1' },
    { title: 'Political Phrases', description: 'US Political Analysis Phrases', image: 'images/political-analysis.png', link: 'https://pap.profoundwords.us/', qrcode: 'images/qr_pap.png?v=1' },
    { title: 'Situation Idioms', description: 'Chinese Idioms in real-life political scenarios', image: 'images/situation-idioms.png?v=1', link: 'https://situation.profoundwords.us/', qrcode: 'images/qr_situation.png?v=1' },
  ];

  const shareBlocks = [
    // { title: 'Bookmarks Helper', description: 'Bookmarks Helper', image: 'images/holiday.png', link: './bookmark' },
    { title: 'WebScope', description: 'Popular Websites & Resources', image: 'images/popular-websites.png', link: './websites' },
    { title: 'Happy Holiday', description: 'Holiday Greeting Cards Helper', image: 'images/holiday.png', link: './holiday' },
    { title: 'Cost$$', description: 'Price-Adjustment', image: 'images/cost$$.png', link: './cost$$' },
  ];

  const secondBlocks = [
    { title: 'Mint Mobile', description: 'Referral Link', image: 'images/mint-mobile.jpg', link: 'http://fbuy.me/uy2-s' },
  ];

  const location = useLocation();
  const isBookmarkPage = location.pathname === '/bookmark';

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className="app-container">
        {!isBookmarkPage && <Header onQRCodeClick={() => handleQrCodeOpen({
            qrcode: qrCodeProfound,
            title: 'Profound Words',
            description: 'Data Value-Added AI Service Platform',
            link: 'https://profoundwords.us/'
          })} />
        }
        <Routes>
            <Route path="/" element={<MainContent blocks={blocks} shareBlocks={shareBlocks} secondBlocks={secondBlocks} onQRCodeClick={handleQrCodeOpen} />} />
            <Route path="/cost$$" element={<Costco />} />
            <Route path="/cost$$/upload" element={<UploadReceipts />} />
            <Route path="/holiday" element={<HolidayCardGenerator />} />
            <Route path="/websites" element={<PopularWebsites />} />
            <Route path="/bookmark" element={(
              <ThemeProvider theme={bookTheme}>
                <Bookmark />
              </ThemeProvider>
            )} />
        </Routes>
        {!isBookmarkPage && <Footer />}
        <QRCodeModal
          open={qrCodeOpen}
          onClose={handleQrCodeClose}
          qrCode={currentQrCode}
          title={currentQrCodeTitle}
          description={currentQrCodeDescription}
          link={currentQrCodeLink}
        />
      </Box>
    </ThemeProvider>
  );
}

export default App;