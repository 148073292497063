import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const EditDialog = ({
    isEditDialogOpen,
    setIsEditDialogOpen,
    setEditItem,
    editItem,
    handleDeleteItem,
    handleUpdateItem
}) => {
  return (
    <>
      <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)}>
        <DialogTitle>{editItem?.children ? 'Edit Category' : 'Edit Bookmark'}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Title"
            value={editItem?.title || ''}
            onChange={(e) => setEditItem({...editItem, title: e.target.value})}
          />
          {!editItem?.children && (
            <>
              <TextField
                fullWidth
                margin="dense"
                label="Link"
                value={editItem?.link || ''}
                onChange={(e) => setEditItem({...editItem, link: e.target.value})}
              />
              <FormControl fullWidth margin="dense">
                <InputLabel>Open Preference</InputLabel>
                <Select
                  value={editItem?.openPreference || 'bookmarkViewer'}
                  onChange={(e) => setEditItem({...editItem, openPreference: e.target.value})}
                >
                  <MenuItem value="newTab">New Tab</MenuItem>
                  <MenuItem value="newWindow">New Window</MenuItem>
                  <MenuItem value="bookmarkViewer">Bookmark Viewer</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteItem} color="secondary">Delete</Button>
          <Button onClick={() => setIsEditDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleUpdateItem} color="primary">Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditDialog;
