import React from 'react';
import { Button, Typography, Box } from '@mui/material';

function CardProducer({ recipients, salutation, greeting, signature, signatureDate, font, image, rectangles, recipientStyle, greetingStyle, signatureStyle }) {
  const getTextStyle = (style) => {
    let fontSize = style.fontSize;
    let fontWeight = style.fontStyle.bold ? 'bold' : 'normal';
    let fontStyle = style.fontStyle.italic ? 'italic' : 'normal';
    return `${fontStyle} ${fontWeight} ${fontSize}px ${font}`;
  };

  function wrapText(ctx, text, x, y, maxWidth, lineHeight) {
    const words = text.split(' ');
    let line = '';
    const lines = [];
  
    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' ';
      const metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        lines.push(line);
        line = words[n] + ' ';
      } else {
        line = testLine;
      }
    }
    lines.push(line);
  
    for (let i = 0; i < lines.length; i++) {
      ctx.fillText(lines[i], x, y + (i * lineHeight));
    }
  }
  

  const produceCards = async () => {
    let count = 0;
    const totalRecipients = recipients.filter(recipient => recipient !== '').length;
  
    for (const recipient of recipients.filter(recipient => recipient !== '')) {
      count++;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
  
      await new Promise((resolve) => {
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
  
          rectangles.forEach((rect, index) => {
            let text, style;
            if (index === 0) {
              text = recipient;
              style = recipientStyle;
            } else if (index === 1) {
              text = greeting;
              style = greetingStyle;
            } else if (index === 2) {
              text = signature;
              style = signatureStyle;
            }

            ctx.font = getTextStyle(style);
            ctx.fillStyle = style.color;
            ctx.textBaseline = 'middle';
            // console.log(text, style, rect);
            if (rect.id === 'name') {
              text = `${salutation} ${text}`;
            }

            if (index === 1) { // For greeting (potentially multi-line)
              const lines = text.split('\n');
              lines.forEach((line, lineIndex) => {
                wrapText(ctx, line, rect.cursorPosition.x - rect.size.width/2, rect.cursorPosition.y + (lineIndex * parseInt(style.fontSize)), rect.size.width, parseInt(style.fontSize))
                // ctx.fillText(line, rect.cursorPosition.x - rect.size.width/2, rect.cursorPosition.y + (lineIndex * parseInt(ctx.font)));
              });
            } else {
              if (rect.id === 'signature' && text !== '') {
                wrapText(ctx, text, rect.cursorPosition.x, rect.cursorPosition.y, rect.size.width, parseInt(style.fontSize));
                ctx.font = `${parseInt(style.fontSize) * 0.8}px ${font}`; // Smaller font for date
                ctx.fillText(signatureDate, rect.cursorPosition.x + rect.size.width * 0.2, rect.cursorPosition.y + rect.size.height * 0.4);
              } else {
                wrapText(ctx, text, rect.cursorPosition.x, rect.cursorPosition.y, rect.size.width, parseInt(style.fontSize))
              }
              // ctx.fillText(text, rect.cursorPosition.x, rect.cursorPosition.y);
            }
          });

        const dataUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.download = `${recipient}.png`;
        link.href = dataUrl;
        link.click();
        resolve();
      };
      img.src = image;
    });

    if (count % 5 === 0 && count < totalRecipients) {
      await new Promise(resolve => setTimeout(resolve, Math.random() * 2000 + 1000));
    }
  }

  alert(`${totalRecipients} images have been produced and downloaded.`);
};

  return (
    <>
    <Box display="flex" alignItems="normal" gap={2} style={{marginTop: '10px'}}>
      <Button onClick={produceCards} size='small' variant="outlined" style={{fontWeight: 'bold', color: 'blueviolet'}}>
        Start To Generate
      </Button>
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        * Generating one personalized greeting card for each recipient. The resulting images will be automatically downloaded.
      </Typography>
    </Box>
    </>
  );
}

export default CardProducer;
