import React from 'react';
import { TextField } from '@mui/material';

function SignatureInput({ setSignature }) {
  return (
    <TextField
      onChange={(e) => setSignature(e.target.value)}
      label="Signature"
      size='small'
    />
  );
}

export default SignatureInput;
