import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Box,
  Paper,
  Chip,
  AppBar,
  Toolbar
} from '@mui/material';
import websitesData from './websites.js';

const PopularWebsites = () => {
  const [websites, setWebsites] = useState([]);
  const [selectedWebsites, setSelectedWebsites] = useState([]);
  const categoryRefs = useRef({});

  useEffect(() => {
    setWebsites(websitesData);
  }, []);

  const handleCheckboxChange = (url) => {
    setSelectedWebsites(prev => 
      prev.includes(url) ? prev.filter(item => item !== url) : [...prev, url]
    );
  };

  const handleSelectAll = () => {
    setSelectedWebsites(websites.map(site => site.url));
  };

  const handleExport = () => {
    const exportData = selectedWebsites.map(url => ({
      url,
      name: websites.find(site => site.url === url).description.split(' ')[0]
    }));
    const blob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'exported_websites.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const scrollToCategory = (category) => {
    categoryRefs.current[category]?.scrollIntoView({ behavior: 'smooth' });
  };

  const categories = [...new Set(websites.flatMap(site => site.categories))];

  return (
    <Box>
      <AppBar position="sticky" color="default" elevation={0}>
        <Toolbar>
            <Box sx={{ 
                flexGrow: 1, 
                display: 'flex', 
                flexWrap: 'wrap',
                gap: 0.4,
                overflowY: 'auto'
                }}>
                {categories.map(category => (
                    <Chip
                    key={category}
                    label={category}
                    onClick={() => scrollToCategory(category)}
                    sx={{ mb: 0.2 }}
                    />
                ))}
            </Box>
          <Button size="small" onClick={handleSelectAll} sx={{ mr: 1 }}>
            Select All
          </Button>
          <Button size="small" variant="contained" onClick={handleExport}>
            Export Selected
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" sx={{ mt: 2 }}>
        {categories.map(category => (
          <Paper key={category} elevation={3} sx={{ mb: 2, p: 2 }} ref={el => categoryRefs.current[category] = el}>
            <Typography 
                variant="h6" 
                component="h2" 
                gutterBottom
                sx={{
                    background: 'linear-gradient(45deg, #3494E6, #EC6EAD)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    letterSpacing: '2px',
                    borderBottom: '2px solid',
                    borderImage: 'linear-gradient(45deg, #3494E6, #EC6EAD) 1',
                    display: 'inline-block',
                    paddingBottom: '8px',
                    textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
                    position: 'relative',
                    '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-4px',
                    left: 0,
                    width: '50%',
                    height: '3px',
                    background: 'linear-gradient(45deg, #3494E6, #EC6EAD)'
                    }
                }}
                >
                {category}
            </Typography>

            <List>
              {websites.filter(site => site.categories.includes(category)).map(site => (
                <ListItem key={site.url} disablePadding>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedWebsites.includes(site.url)}
                      onChange={() => handleCheckboxChange(site.url)}
                    />
                  </ListItemIcon>
                  <ListItemIcon>
                    <Box
                      component="img"
                      src={site.favicon}
                      alt=""
                      sx={{
                        width: 16,
                        height: 16,
                        display: 'block',
                        objectFit: 'contain'
                      }}
                      onError={(e) => { e.target.style.display = 'none' }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                        <Typography 
                        variant="body1" 
                        component="a" 
                        href={site.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        sx={{
                            fontWeight: 'bold',
                            color: 'primary.main',
                            textDecoration: 'none',
                            '&:hover': {
                            textDecoration: 'underline',
                            },
                        }}
                        >
                        {site.name}
                        </Typography>
                    }
                    secondary={
                        <>
                          <Typography 
                            variant="caption" 
                            sx={{ 
                              display: 'block',
                              color: 'text.secondary',
                              fontStyle: 'italic',
                            }}
                          >
                            {site.description}
                          </Typography>
                          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            {(site.estimatedMonthlyVisits / 1000000).toFixed(2)}M visits/month
                          </Typography>
                        </>
                      }
                  />
                  <Chip label={category} size="small" sx={{ ml: 1 }} />
                </ListItem>
              ))}
            </List>
          </Paper>
        ))}
      </Container>
    </Box>
  );
};

export default PopularWebsites;
