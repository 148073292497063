import React from "react";
import {IconButton, Dialog, DialogTitle, DialogContent} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import BookmarkForm from "./BookmarkForm";

const BookmarkDialog = ({
    isBookmarkModalOpen,
    setIsBookmarkModalOpen,
    handleAddBookmark,
    bookmarks
}) => {
  return (
    <>
      <Dialog 
        open={isBookmarkModalOpen} 
        onClose={() => setIsBookmarkModalOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Add Bookmark
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setIsBookmarkModalOpen(false)}
            aria-label="close"
            style={{ position: 'absolute', right: 16, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <BookmarkForm onAdd={handleAddBookmark} categories={bookmarks} onClose={() => setIsBookmarkModalOpen(false)} />
        </DialogContent>
      </Dialog>    
    </>
  );
};

export default BookmarkDialog;
