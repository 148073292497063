import React from 'react';
import { Select, MenuItem } from '@mui/material';

const fonts = [
  'Monotype Corsiva',
  'Arial',
  'Times New Roman',
  'Brush Script MT',
  'Copperplate',
  'Lucida Handwriting',
  'Papyrus',
  'Edwardian Script ITC',
  'Vivaldi',
  'Gabriola'
];

function FontSelector({ font, setFont }) {
  return (
    <Select 
      value={font} 
      onChange={(e) => setFont(e.target.value)} 
      defaultValue={fonts[0]} 
      size='small'
      fullWidth
    >
      {fonts.map(font => (
        <MenuItem key={font} value={font} style={{ fontFamily: font }}>
          {font}
        </MenuItem>
      ))}
    </Select>
  );
}

export default FontSelector;
