import React from 'react';
import { Typography, Container, Button, Box } from '@mui/material';
import { Email as EmailIcon } from '@mui/icons-material';

const Footer = () => (
  <Box component="footer" className="footer">
    <Container maxWidth="lg">
      <Typography variant="body2" color="text.secondary" align="center">
        Copyright © 2024 Profound Words - All Rights Reserved.
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center" className="contact-email">
        <Button
          startIcon={<EmailIcon />}
          href="mailto:profoundwords.us@gmail.com"
          color="inherit"
          size="small"
        >
          Contact Email
        </Button>
      </Typography>
    </Container>
  </Box>
);

export default Footer;