// GreetingEditor.jsx
import React from 'react';
import { TextField } from '@mui/material';

function GreetingEditor({ greeting, setGreeting }) {
  const handleGreetingChange = (event) => {
    setGreeting(event.target.value);
  };

  return (
    <TextField
      fullWidth
      multiline
      rows={3}
      value={greeting}
      onChange={handleGreetingChange}
      label="Edit Greeting"
      variant="outlined"
      margin="dense"
      size='small'
    />
  );
}

export default GreetingEditor;
