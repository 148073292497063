import React from "react";
import { Grid, Paper, Fab, IconButton} from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import FolderAddIcon from '@mui/icons-material/CreateNewFolder';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import BookmarkTree from "./BookmarkTree";

const LeftPanel = ({
    setIsDefaultBookmarksModalOpen,
    setIsCategoryModalOpen,
    setIsBookmarkModalOpen,
    toggleLeftPanel,
    isLeftPanelCollapsed,
    bookmarks,
    handleSelectBookmark,
    handleEditItem
}) => {
  return (
    <>
      <Grid item style={{ minWidth: '180px', flexBasis: isLeftPanelCollapsed ? '40px' : '200px', flexGrow: 0, flexShrink: 0 }}>
        <Paper elevation={1} style={{ padding: '8px', height: '100%', position: 'relative'}}>
          <Fab
            color="default"
            aria-label="load default bookmarks"
            size="small"
            style={{
              position: 'absolute',
              top: -10,
              right: 88,
              width: 32,
              height: 32,
              minHeight: 'unset'
            }}
            onClick={() => setIsDefaultBookmarksModalOpen(true)}
          >
            <CloudDownloadIcon fontSize="inherit" />
          </Fab>
          <Fab
            color="secondary" 
            aria-label="add category"
            size="small"
            style={{
              position: 'absolute',
              top: -10,
              right: 48,
              width: 32,
              height: 32,
              minHeight: 'unset'
            }}
            onClick={() => setIsCategoryModalOpen(true)}
          >
            <FolderAddIcon fontSize="inherit" />
          </Fab>
          <Fab
            color="primary" 
            aria-label="add bookmark"
            size="small"
            style={{
              position: 'absolute',
              top: -10,
              right: 8,
              width: 32,
              height: 32,
              minHeight: 'unset'
            }}
            onClick={() => setIsBookmarkModalOpen(true)}
          >
            <BookmarkAddIcon fontSize="inherit" />
          </Fab>
          <IconButton
            onClick={toggleLeftPanel}
            style={{
              position: 'absolute',
              top: '50%',
              right: -20,
              transform: 'translateY(-50%)',
              backgroundColor: 'white',
              boxShadow: '0 0 5px rgba(0,0,0,0.2)',
              zIndex: 1,
            }}
            size="small"
          >
            {isLeftPanelCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
          {!isLeftPanelCollapsed && (
            <BookmarkTree bookmarks={bookmarks} onSelect={handleSelectBookmark} onEdit={handleEditItem} />
          )}
        </Paper>
      </Grid>
    </>
  );
};

export default LeftPanel;
