import React, { useState } from "react";
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid, RadioGroup, FormControlLabel, Radio, Box, FormLabel } from "@mui/material";

const BookmarkForm = ({ onAdd, categories }) => {
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [openPreference, setOpenPreference] = useState("newTab");

  const handleSubmit = () => {
    if (title && link) {
      onAdd({
        id: Date.now().toString(),
        title,
        link,
        openPreference,
        categoryId: categoryId || null // Allow null categoryId
      }, categoryId);
      setTitle("");
      setLink("");
      setCategoryId("");
      setOpenPreference("newTab");
    }
  };
  

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormLabel component="legend" sx={{ marginRight: 2 }}>Open Preference</FormLabel>
            <RadioGroup
              row
              value={openPreference}
              onChange={(e) => setOpenPreference(e.target.value)}
            >
              <FormControlLabel value="newTab" control={<Radio />} label="New Tab" />
              <FormControlLabel value="newWindow" control={<Radio />} label="New Window" />
              <FormControlLabel value="bookmarkViewer" control={<Radio />} label="Viewer" />
            </RadioGroup>
          </Box>
        </Grid>
        <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>Category (Optional)</InputLabel>
          <Select
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {categories.filter(category => category.children).map((category) => {
              return (
              <MenuItem key={category.id} value={category.id}>
                {category.title}
              </MenuItem>);
            })}
          </Select>
        </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </Grid>
        {/* Positioning Add Bookmark Button */}
        <Grid item xs={12} container justifyContent="flex-end">
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Add Bookmark
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookmarkForm;
