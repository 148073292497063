import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';

function CardPreview({ image, rectangles, salutation, setRectangles, recipient, greeting, signature, signatureDate, font, recipientStyle, greetingStyle, signatureStyle }) {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const imgRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (image) {
      const img = new Image();
      img.onload = () => {
        setImageSize({ width: img.width, height: img.height });
      };
      img.src = image;
    }
  }, [image]);

  const handleDrag = (id, e, ui) => {
    if (imgRef.current) {
      const imgRect = imgRef.current.getBoundingClientRect();
      const dotRect = e.target.getBoundingClientRect();

      const relativeX = dotRect.left - imgRect.left + dotRect.width / 2;
      const relativeY = dotRect.top - imgRect.top + dotRect.height / 2;
  
      // console.log(`Red dot coordinates for ${id}: X: ${relativeX}, Y: ${relativeY}`);
      if (relativeX === dotRect.width / 2 && relativeY === dotRect.height / 2) {
        console.log('the cursor is not in the rectangle');
        return;
      }

      const x = e.clientX - imgRect.left;
      const y = e.clientY - imgRect.top;
      setRectangles(rectangles.map(rect =>
        rect.id === id ? { ...rect, position: ui, cursorPosition: { x: Math.round(relativeX), y: Math.round(relativeY) } } : rect
      ));
    }
  };

  const handleResize = (id, e, { size }) => {
    console.log('handleResize', size);
    setRectangles(rectangles.map(rect =>
      rect.id === id ? { ...rect, size: { width: size.width, height: size.height } } : rect
    ));
  };

  useEffect(() => {
    const handleGlobalMouseMove = (e) => {
      if (imgRef.current) {
        const rect = imgRef.current.getBoundingClientRect();
        if (e.clientX >= rect.left && e.clientX <= rect.right &&
            e.clientY >= rect.top && e.clientY <= rect.bottom) {
          const x = e.clientX - rect.left;
          const y = e.clientY - rect.top;
          setCursorPosition({ x: Math.round(x), y: Math.round(y) });
        }
      }
    };

    document.addEventListener('mousemove', handleGlobalMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleGlobalMouseMove);
    };
  }, []);

  const getStyleForRectangle = (id) => {
    let style = { fontFamily: font };
    if (id === 'name') {
      style = { ...style, ...getTextStyle(recipientStyle) };
    } else if (id === 'greeting') {
      style = { ...style, ...getTextStyle(greetingStyle) };
    } else if (id === 'signature') {
      style = { ...style, ...getTextStyle(signatureStyle) };
    }
    return style;
  };

  const getTextStyle = (style) => {
    return {
      fontSize: `${style.fontSize}px`,
      fontWeight: style.fontStyle.bold ? 'bold' : 'normal',
      fontStyle: style.fontStyle.italic ? 'italic' : 'normal',
      color: style.color,
    };
  };

  return (
    <Box sx={{ position: 'relative', display: 'inline-block', overflow: 'auto' }}>
      {image && (
        <>
          <img
            ref={imgRef}
            src={image}
            alt="Preview"
            style={{ width: imageSize.width, height: imageSize.height }}
          />
          <div style={{
            position: 'absolute',
            top: 5,
            left: 5,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            padding: '5px',
            borderRadius: '3px',
            fontSize: '12px'
          }}>
            X: {cursorPosition.x}, Y: {cursorPosition.y}
          </div>
        </>
      )}
      {rectangles.map(({ id, position, size, cursorPosition, fontSize }) => (
        <Draggable
          key={id}
          position={position}
          onDrag={(e, ui) => handleDrag(id, e, ui)}
          bounds="parent"
          handle='.handle'
        >
          <Resizable
            width={size.width}
            height={size.height}
            onResize={(e, data) => handleResize(id, e, data)}
            resizeHandles={['se']}
          >
            <div style={{
              border: '2px dashed black',
              position: 'absolute',
              width: size.width,
              height: size.height,
              left: position.x,
              top: position.y,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              overflow: 'hidden',
              padding: '5px',
              boxSizing: 'border-box',
              ...getStyleForRectangle(id)
            }}>
              <div style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden'
              }}>
                {id === 'name' && recipient !== '' && `${salutation} ${recipient}`}
                {id === 'greeting' && greeting}
                {id === 'signature' && signature}
              </div>
              {/* Red dot handle */}
                <div 
                  className="handle"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 0, 255, 0.5)',
                    transform: 'translate(-50%, -50%)',
                    cursor: 'move'
                  }}
                />
              {cursorPosition && (
                <div style={{
                  position: 'absolute',
                  bottom: 5,
                  left: 5,
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  padding: '2px 5px',
                  borderRadius: '3px',
                  fontSize: '8px'
                }}>
                  X: {Math.round(cursorPosition.x)}, Y: {Math.round(cursorPosition.y)}
                </div>
              )}
              {id === 'signature' && signature !== '' && (
                <>
                  <div style={{
                    fontSize: `10px`,
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                  }}>
                    {signatureDate}
                  </div>
                </>
              )}
            </div>
          </Resizable>
        </Draggable>
      ))}
    </Box>
  );
}

export default CardPreview;
