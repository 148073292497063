import React, { useState } from 'react';
import { IconButton, Popover, Slider, Typography, Box } from '@mui/material';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { SketchPicker } from 'react-color';

function TextStyleControls({ fontSize, setFontSize, fontStyle, setFontStyle, color, setColor }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [colorAnchorEl, setColorAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorClick = (event) => {
    setColorAnchorEl(event.currentTarget);
  };

  const handleColorClose = () => {
    setColorAnchorEl(null);
  };

  const handleFontSizeChange = (event, newValue) => {
    setFontSize(newValue);
  };

  const toggleBold = () => {
    setFontStyle(prev => ({ ...prev, fontStyle: {...prev.fontStyle, bold: !prev.fontStyle.bold} }));
  };

  const toggleItalic = () => {
    setFontStyle(prev => ({ ...prev, fontStyle: {...prev.fontStyle, italic: !prev.fontStyle.italic} }));
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  return (
    <>
      <IconButton onClick={handleClick} size='small'>
        <FormatSizeIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ padding: '20px', width: '200px' }}>
          <Typography id="font-size-slider" gutterBottom>
            Font Size: {fontSize}px
          </Typography>
          <Slider
            value={fontSize}
            onChange={handleFontSizeChange}
            aria-labelledby="font-size-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={8}
            max={84}
          />
        </Box>
      </Popover>
      <IconButton onClick={toggleBold} size='small'>
        <FormatBoldIcon color={fontStyle.bold ? 'primary' : 'inherit'} />
      </IconButton>
      <IconButton onClick={toggleItalic} size='small'>
        <FormatItalicIcon color={fontStyle.italic ? 'primary' : 'inherit'} />
      </IconButton>
      <IconButton onClick={handleColorClick} size='small'>
        <ColorLensIcon style={{ color: color }} />
      </IconButton>
      <Popover
        anchorEl={colorAnchorEl}
        open={Boolean(colorAnchorEl)}
        onClose={handleColorClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SketchPicker color={color} onChange={handleColorChange} />
      </Popover>
    </>
  );
}

export default TextStyleControls;
