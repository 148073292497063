import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container, Typography, Grid, Paper, Box, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import RecipientList from './RecipientList';
import GreetingSelector from './GreetingSelector';
import GreetingEditor from './GreetingEditor';
import SignatureInput from './SignatureInput';
import FontSelector from './FontSelector';
import ImageSelector from './ImageSelector';
import CardPreview from './CardPreview';
import CardProducer from './CardProducer';
import TextStyleControls from './TextStyleControls';

// Create a holiday theme
const holidayTheme = createTheme({
  palette: {
    primary: {
      main: '#ed8cb4', // A festive red color
    },
    background: {
      default: '#fff9c4', // A warm, light yellow background
    },
  },
  typography: {
    fontFamily: '"Aire Bold", "Roboto", sans-serif',
    h5: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
  },
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const ScrollableBox = styled(Box)(({ theme }) => ({
  maxHeight: '200px',
  overflowY: 'auto',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  marginTop: '3px',
  marginBottom: '0px',
  color: 'blueviolet'
}));

function HolidayCardGenerator() {
  const [recipients, setRecipients] = useState(['Friend']);
  const [greeting, setGreeting] = useState('');
  const [signature, setSignature] = useState('');
  const [font, setFont] = useState('Monotype Corsiva');
  const [image, setImage] = useState(null);
  const [rectangles, setRectangles] = useState([
    { id: 'name', position: { x: 50, y: 30 }, cursorPosition: { x: 120, y: 110 }, size: { width: 150, height: 80 } },
    { id: 'greeting', position: { x: 50, y: 120 }, cursorPosition: { x: 300, y: 250 }, size: { width: 300, height: 200 } },
    { id: 'signature', position: { x: 50, y: 250 }, cursorPosition: { x: 390, y: 390 }, size: { width: 150, height: 80 } },
  ]);
  const [recipientStyle, setRecipientStyle] = useState({ fontSize: '25', fontStyle: { bold: true, italic: false }, color: '#000000' });
  const [greetingStyle, setGreetingStyle] = useState({ fontSize: '35', fontStyle: { bold: true, italic: false }, color: '#000000' });
  const [signatureStyle, setSignatureStyle] = useState({ fontSize: '20', fontStyle: { bold: true, italic: false }, color: '#000000' });

  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [salutation, setSalutation] = useState('Dear');
  const [signatureDate, setSignatureDate] = useState(
    new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
  );

  return (
    <ThemeProvider theme={holidayTheme}>
      <Container>
        <StyledTitle variant="h5">Holiday Greeting Cards Helper</StyledTitle>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={3}>
            <Paper elevation={2} sx={{ p: { xs: 1, sm: 2 } }}>
              <Box alignItems="center" justifyContent="space-between">
                <TextField
                  label="Salutation"
                  value={salutation}
                  onChange={(e) => setSalutation(e.target.value)}
                  size='small'
                  style={{width: '120px'}}
                />
                <SectionTitle>Recipients</SectionTitle>
                <TextStyleControls
                  fontSize={recipientStyle.fontSize}
                  setFontSize={(size) => setRecipientStyle(prev => ({ ...prev, fontSize: size }))}
                  fontStyle={recipientStyle.fontStyle}
                  setFontStyle={setRecipientStyle}
                  color={recipientStyle.color}
                  setColor={(color) => setRecipientStyle(prev => ({ ...prev, color: color }))}
                />
              </Box>
              <ScrollableBox>
                <RecipientList recipients={recipients} setRecipients={setRecipients} />
              </ScrollableBox>
              
              <Box alignItems="center" justifyContent="space-between">
                <SectionTitle>Greeting</SectionTitle>
                <TextStyleControls
                  fontSize={greetingStyle.fontSize}
                  setFontSize={(size) => setGreetingStyle(prev => ({ ...prev, fontSize: size }))}
                  fontStyle={greetingStyle.fontStyle}
                  setFontStyle={setGreetingStyle}
                  color={greetingStyle.color}
                  setColor={(color) => setGreetingStyle(prev => ({ ...prev, color: color }))}
                />
              </Box>
              <GreetingSelector setGreeting={setGreeting} />
              <GreetingEditor greeting={greeting} setGreeting={setGreeting} />
              
              <Box alignItems="center" justifyContent="space-between">
                <SectionTitle>Signature</SectionTitle>
                <TextStyleControls
                  fontSize={signatureStyle.fontSize}
                  setFontSize={(size) => setSignatureStyle(prev => ({ ...prev, fontSize: size }))}
                  fontStyle={signatureStyle.fontStyle}
                  setFontStyle={setSignatureStyle}
                  color={signatureStyle.color}
                  setColor={(color) => setSignatureStyle(prev => ({ ...prev, color: color }))}
                />
              </Box>
              <SignatureInput signature={signature} setSignature={setSignature} />
              <TextField
                label="Signature Date"
                value={signatureDate}
                onChange={(e) => setSignatureDate(e.target.value)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                size='small'
              />
              <SectionTitle>Font</SectionTitle>
              <FontSelector font={font} setFont={setFont} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Paper elevation={0} sx={{ p: 1, paddingTop: 0 }}>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={12} sm={6} md={4}>
                <SectionTitle>Image Selection</SectionTitle>
                <ImageSelector
                  setImage={setImage}
                  onImageLoad={(dimensions) => setImageDimensions(dimensions)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <CardProducer
                  recipients={recipients}
                  greeting={greeting}
                  signature={signature}
                  font={font}
                  image={image}
                  rectangles={rectangles}
                  recipientStyle={recipientStyle}
                  greetingStyle={greetingStyle}
                  signatureStyle={signatureStyle}
                  salutation={salutation}
                  signatureDate={signatureDate}
                />
              </Grid>
            </Grid>
              <Box mt={2}>
                <SectionTitle>Card Preview</SectionTitle>
                {imageDimensions.width > 0 && (
                  <>
                    <Typography variant="body2" sx={{ mb: 0 }}>
                      Image size: {imageDimensions.width} x {imageDimensions.height} pixels
                    </Typography>
                    <Typography variant="caption" sx={{ mb: 0 }}>
                      (1) Drag the semi-transparent blue handle to reposition text elements. 
                    </Typography>
                    <Typography variant="caption" sx={{ mb: 0 }}>
                      (2) Adjust element size by manipulating the bottom-right resize control.
                    </Typography>                  
                  </>
                )}
                <CardPreview
                  image={image}
                  rectangles={rectangles}
                  setRectangles={setRectangles}
                  recipient={recipients[0] || ''}
                  greeting={greeting}
                  signature={signature}
                  font={font}
                  recipientStyle={recipientStyle}
                  greetingStyle={greetingStyle}
                  signatureStyle={signatureStyle}
                  salutation={salutation}
                  signatureDate={signatureDate}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default HolidayCardGenerator;
