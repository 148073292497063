import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import { Box, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DefaultBookmarksDialog = ({
    isDefaultBookmarksModalOpen,
    setIsDefaultBookmarksModalOpen,
    defaultBookmarks,
    handleImportDefaultBookmarks,
}) => {
    const [selectedBookmarks, setSelectedBookmarks] = useState({});

    const handleToggleCategory = (categoryId) => {
        setSelectedBookmarks(prev => {
            const newState = { ...prev };
            const isSelected = !prev[categoryId];
            newState[categoryId] = isSelected;
            defaultBookmarks[categoryId].bookmarks.forEach(bookmark => {
                newState[`${categoryId}-${bookmark.id}`] = isSelected;
            });
            return newState;
        });
    };

    const handleToggleBookmark = (categoryId, bookmarkId) => {
        setSelectedBookmarks(prev => ({
            ...prev,
            [`${categoryId}-${bookmarkId}`]: !prev[`${categoryId}-${bookmarkId}`]
        }));
    };

    const handleImport = () => {
        const bookmarksToImport = Object.entries(selectedBookmarks)
            .flatMap(([key, isSelected]) => {
                if (isSelected) {
                    const [categoryId] = key.split('-');
                    return defaultBookmarks[categoryId].bookmarks.find(b => b.id === key.split('-')[1]);
                }
                return [];
            });
        handleImportDefaultBookmarks(bookmarksToImport);
    };

    return (
        <Dialog
            open={isDefaultBookmarksModalOpen}
            onClose={() => setIsDefaultBookmarksModalOpen(false)}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setIsDefaultBookmarksModalOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Button variant="contained" color="primary" onClick={handleImport} sx={{marginBottom: 1}}>
                    Import Selected Bookmarks
                </Button>
                <Box>
                    {/* Table Header */}
                    <Box sx={{ display: 'flex', fontWeight: 'bold', backgroundColor: '#e0e0e0', padding: 1 }}>
                        <Box sx={{ width: '140px' }}>Category</Box>
                        <Box>Popular Websites</Box>
                    </Box>
                    
                    {Object.entries(defaultBookmarks).map(([categoryId, category], index) => (
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                mb: 1, 
                                backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#ffffff',
                                p: 0,
                                borderRadius: 1
                            }} 
                            key={categoryId}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedBookmarks[categoryId] === true}
                                        onChange={() => handleToggleCategory(categoryId)}
                                        size="small"
                                    />
                                }
                                label={category.name}
                                sx={{ width: '120px', fontSize: 'x-small' }} // Set fixed width for category column
                            />
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', ml: 1 }}>
                                {category.bookmarks.map(bookmark => (
                                    <FormControlLabel
                                        key={bookmark.id}
                                        control={
                                            <Checkbox
                                                checked={!!selectedBookmarks[`${categoryId}-${bookmark.id}`]}
                                                onChange={() => handleToggleBookmark(categoryId, bookmark.id)}
                                                size="small"
                                            />
                                        }
                                        label={
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <img 
                                                    src={bookmark.favicon} 
                                                    alt="" 
                                                    style={{ width: 16, height: 16, marginRight: 8 }}
                                                    onError={(e) => { e.target.style.display = 'none' }}
                                                />
                                                {bookmark.title}
                                            </Box>
                                        }
                                    />
                                ))}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DefaultBookmarksDialog;
