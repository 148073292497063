// GreetingSelector.jsx
import React, {useEffect} from 'react';
import { TextField, MenuItem } from '@mui/material';

const defaultGreetings = {
  happyNewYear: "Happy New Year!",
  NewYear: "Wishing you a prosperous and joyful New Year filled with love and laughter!",
  ChineseNewYear: "恭喜发财，新年快乐！",
  traditionalChineseNewYear: "恭喜發財，新年快樂！",
  Thanksgiving: "Wishing you a joyful Thanksgiving filled with love and gratitude!",
  NewYearAlternative1: "Cheers to a new year and another chance for us to get it right!",
  NewYearAlternative2: "May this New Year bring you peace, happiness, and success!",
  NewYearAlternative3: "Happy New Year! May your year be filled with new adventures and great achievements!",
  NewYearAlternative4: "Here’s to a year of health, happiness, and prosperity. Happy New Year!",
};

function GreetingSelector({ setGreeting }) {

  useEffect(() => {
    setGreeting(defaultGreetings.happyNewYear);
  }, []);

  const handleGreetingChange = (event) => {
    setGreeting(event.target.value);
  };

  return (
    <TextField
      select
      fullWidth
      label="Select a greeting"
      onChange={handleGreetingChange}
      defaultValue=""
      size='small'
    >
      <MenuItem value="">Select a greeting</MenuItem>
      {Object.entries(defaultGreetings).map(([key, value]) => (
        <MenuItem key={key} value={value}>{value}</MenuItem>
      ))}
    </TextField>
  );
}

export default GreetingSelector;
