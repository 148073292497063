import React, { useState, useEffect } from "react";
import { Grid, Paper} from "@mui/material";
import BookmarkViewer from "./components/BookmarkViewer";
import DefaultBookmarksDialog from "./components/DefaultBookmarksDialog";
import defaultBookmarks from "./defaultBookmarks";
import EditDialog from "./components/EditDialog";
import CategoryDialog from "./components/CategoryDialog";
import BookmarkDialog from "./components/BookmarkDialog";
import LeftPanel from "./components/LeftPanel";

const Bookmark = () => {
  const [bookmarks, setBookmarks] = useState(() => {
    const savedBookmarks = localStorage.getItem('bookmarks');
    return savedBookmarks ? JSON.parse(savedBookmarks) : [];
  });
  const [selectedUrl, setSelectedUrl] = useState("");
  const [isBookmarkModalOpen, setIsBookmarkModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isLeftPanelCollapsed, setIsLeftPanelCollapsed] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [newCategoryTitle, setNewCategoryTitle] = useState("");
  const [isDefaultBookmarksModalOpen, setIsDefaultBookmarksModalOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
  }, [bookmarks]);

  const handleAddBookmark = (bookmark, categoryId) => {
    setBookmarks(prevBookmarks => {
      if (!categoryId) {
        return [...prevBookmarks, bookmark];
      }
      return prevBookmarks.map(category => {
        if (category.id === categoryId) {
          return {
            ...category,
            children: [...category.children, bookmark]
          };
        }
        return category;
      });
    });
    setIsBookmarkModalOpen(false);
  };  

  const handleAddCategory = () => {
    const newCategory = {
      id: Date.now().toString(),
      title: newCategoryTitle,
      children: []
    };
    setBookmarks(prevBookmarks => [...prevBookmarks, newCategory]);
    setNewCategoryTitle("");
    setIsCategoryModalOpen(false);
  };

  const handleSelectBookmark = (itemId) => {
    const findBookmark = (categories) => {
      for (let category of categories) {
        if (category.id === itemId) return category;
        if (category.children) {
          const found = category.children.find(b => b.id === itemId);
          if (found) return found;
        }
      }
      return null;
    };
  
    const selectedBookmark = findBookmark(bookmarks.filter(b => b !== null));
    if (selectedBookmark && selectedBookmark.link) {
      switch (selectedBookmark.openPreference) {
        case 'newTab':
          window.open(selectedBookmark.link, '_blank');
          break;
        case 'newWindow':
          window.open(selectedBookmark.link, '_blank', 'noopener,noreferrer');
          break;
        case 'bookmarkViewer':
        default:
          setSelectedUrl(selectedBookmark.link);
      }
    }
  };  

  const toggleLeftPanel = () => {
    setIsLeftPanelCollapsed(!isLeftPanelCollapsed);
  };

  const handleEditItem = (item) => {
    setEditItem(item);
    setIsEditDialogOpen(true);
  };

  const handleUpdateItem = () => {
    setBookmarks(prevBookmarks => {
      return prevBookmarks.map(category => {
        if (category.id === editItem.id) {
          return editItem;
        }
        return {
          ...category,
          children: category.children.map(b => b.id === editItem.id ? editItem : b)
        };
      });
    });
    setIsEditDialogOpen(false);
  };

  const handleDeleteItem = () => {
    setBookmarks(prevBookmarks => {
      return prevBookmarks?.filter(category => category.id !== editItem.id)
        .map(category => ({
          ...category,
          children: category.children?.filter(b => b.id !== editItem.id)
        }));
    });
    setIsEditDialogOpen(false);
  };

  const handleImportDefaultBookmarks = (selectedBookmarks) => {
    setBookmarks(prevBookmarks => [...prevBookmarks, ...selectedBookmarks]);
    setIsDefaultBookmarksModalOpen(false);
  };
  
  return (
    <Grid container spacing={1} style={{margin:'2px'}}>
      <LeftPanel
        setIsDefaultBookmarksModalOpen={setIsDefaultBookmarksModalOpen}
        setIsCategoryModalOpen={setIsCategoryModalOpen}
        setIsBookmarkModalOpen={setIsBookmarkModalOpen}
        toggleLeftPanel={toggleLeftPanel}
        isLeftPanelCollapsed={isLeftPanelCollapsed}
        bookmarks={bookmarks}
        handleSelectBookmark={handleSelectBookmark}
        handleEditItem={handleEditItem}
      />
      <Grid item xs>
        <Paper elevation={1} style={{ padding: '8px', height: '100%' }}>
          {selectedUrl ? (
            <BookmarkViewer url={selectedUrl} />
          ) : (
            <div>Select a bookmark to view</div>
          )}
        </Paper>
      </Grid>
      <BookmarkDialog
        isBookmarkModalOpen={isBookmarkModalOpen}
        setIsBookmarkModalOpen={setIsBookmarkModalOpen}
        handleAddBookmark={handleAddBookmark}
        bookmarks={bookmarks}
      />
      <CategoryDialog
        isCategoryModalOpen={isCategoryModalOpen}
        setIsCategoryModalOpen={setIsCategoryModalOpen}
        newCategoryTitle={newCategoryTitle}
        setNewCategoryTitle={setNewCategoryTitle}
        handleAddCategory={handleAddCategory}
      />
      <EditDialog
        isEditDialogOpen={isEditDialogOpen}
        setIsEditDialogOpen={setIsEditDialogOpen}
        editItem={editItem}
        setEditItem={setEditItem}
        handleDeleteItem={handleDeleteItem}
        handleUpdateItem={handleUpdateItem}
      />
      <DefaultBookmarksDialog
        isDefaultBookmarksModalOpen={isDefaultBookmarksModalOpen}
        setIsDefaultBookmarksModalOpen={setIsDefaultBookmarksModalOpen}
        defaultBookmarks={defaultBookmarks}
        handleImportDefaultBookmarks={handleImportDefaultBookmarks}
      />
    </Grid>
  );
};

export default Bookmark;