import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';

// Import images
import holiday1 from './images/holiday4.png';
import holiday2 from './images/holiday2.png';
import holiday3 from './images/holiday3.png';
import holiday4 from './images/holiday1.png';
import newYear1 from './images/newYear1.png';
import newYear2 from './images/newYear2.png';
import newYear3 from './images/newYear3.png';

const holidayImages = [
  { name: 'Holiday 1', src: holiday1 },
  { name: 'Holiday 2', src: holiday2 },
  { name: 'Holiday 3', src: holiday3 },
  { name: 'Holiday 4', src: holiday4 },
  { name: 'New Year 1', src: newYear1 },
  { name: 'New Year 2', src: newYear2 },
  { name: 'New Year 3', src: newYear3 },
];

function ImageSelector({ setImage, onImageLoad }) {
  const [selectedImage, setSelectedImage] = useState(holidayImages[0].src);

  useEffect(() => {
    setImage(selectedImage);
    loadImage(selectedImage);
  }, []);

  const loadImage = (src) => {
    const img = new Image();
    img.onload = () => {
      onImageLoad({ width: img.width, height: img.height });
    };
    img.src = src;
  };

  const handleImageChange = (event) => {
    const selected = event.target.value;
    if (selected === 'upload') {
      document.getElementById('imageUpload').click();
    } else {
      setSelectedImage(selected);
      setImage(selected);
      loadImage(selected);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
        setImage(e.target.result);
        loadImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <FormControl sx={{ width: '200px' }}>
      <Select
        id="holiday-image-select"
        value={selectedImage}
        onChange={handleImageChange}
        size='small'
      >
        {holidayImages.map((img, index) => (
          <MenuItem key={index} value={img.src}>
            {img.name}
          </MenuItem>
        ))}
        <MenuItem value="upload">Upload Image</MenuItem>
      </Select>
      <input
        type="file"
        id="imageUpload"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleFileUpload}
      />
    </FormControl>
  );
}

export default ImageSelector;
