import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Paper,
  Snackbar,
  Alert,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardMedia,
  CardContent
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';
import config from '../config';

function UploadReceipts() {
  const navigate = useNavigate();
  const [nickname, setNickname] = useState('');
  const [email, setEmail] = useState('');
  const [receiptsData, setReceiptsData] = useState('');
  const [isNeedNotification, setIsNeedNotification] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openGuide, setOpenGuide] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch(`${config.cost.url}/purchases`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nickname: nickname,
          email: email,
          isNeedNotification: isNeedNotification,
          purchaseData: JSON.parse(receiptsData)
        }),
      });

      if (response.ok) {
        setSnackbarMessage('Receipts uploaded successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        // Clear form fields after successful submission
        setNickname('');
        setEmail('');
        setReceiptsData('');
        setIsNeedNotification(false);
      } else {
        throw new Error('Failed to upload receipts');
      }
    } catch (error) {
      console.error('Error uploading receipts:', error);
      setSnackbarMessage('Failed to upload receipts. Please try again.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const guideSteps = [
    { 
      icon: <LooksOneIcon />, 
      text: "Visit www.cost$$.com and sign in to your Cost$$ account.",
      image: null // No image for this step
    },
    { 
      icon: <LooksTwoIcon />, 
      text: "Press F12 on your keyboard (or right-click and select 'Inspect'). Click on the 'Network' tab at the top. Type 'order' in the search box.",
      image: "/images/cost$$/step2.jpg" // Add the path to your image
    },
    { 
      icon: <Looks3Icon />, 
      text: "Click on your account name, then select 'Orders and Purchases'. Look for a request named 'graphql' in the Network tab. Click on it and find 'client-identifier' and 'cost$$-x-authorization' under Headers. Copy their values.",
      image: "/images/cost$$/step3.jpg" // Add the path to your image
    },
    { 
      icon: <Looks4Icon />, 
      text: "Download the cost$$.js file we provided. Open it with a text editor (like Notepad). Replace 'YOUR_CLIENT_IDENTIFIER' and 'YOUR_TOKEN' with the values you copied.",
      image: null,
      downloadLink: "/images/cost$$/cost$$.js"
    },
    { 
      icon: <Looks5Icon />, 
      text: "Go back to the browser, click on 'Console' in Developer Tools. Paste the entire script and press Enter.",
      image: null
    },
    { 
      icon: <Looks6Icon />, 
      text: "You'll see a Promise return an object {data}. Right-click on this object and choose 'Copy object'.",
      image: "/images/cost$$/step6.jpg" // Add the path to your image
    }
  ];

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 2 }}>
        <Button variant="outlined" onClick={() => navigate('/cost$$')}>
          Go back
        </Button>
      </Box>
      <Paper elevation={3} sx={{ p: 4, position: 'relative' }}>
        <Typography variant="h4" gutterBottom sx={{ 
          textAlign: 'center', 
          color: '#1976d2',
          fontWeight: 'bold',
          marginBottom: '20px'
        }}>
          Upload Receipts
        </Typography>
        <Tooltip title="How to get Receipts Data from Cost$$ website" placement="left">
          <IconButton 
            sx={{ position: 'absolute', top: 20, right: 20 }}
            onClick={() => setOpenGuide(true)}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Nickname"
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                label="Email Address"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                required
              />
            </Grid>
          </Grid>
          <TextField
            fullWidth
            label="Receipts Data (JSON)"
            value={receiptsData}
            onChange={(e) => setReceiptsData(e.target.value)}
            margin="normal"
            required
            multiline
            rows={7}
            helperText={
              <Typography 
                variant="caption" 
                onClick={() => setOpenGuide(true)} 
                style={{ cursor: 'pointer', textDecoration: 'underline', color: 'red' }}
              >
                Paste Receipts Data obtained from Cost$$ website by following these steps.
              </Typography>
            }
            FormHelperTextProps={{
              onClick: () => setOpenGuide(true),
              style: { cursor: 'pointer' }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isNeedNotification}
                onChange={(e) => setIsNeedNotification(e.target.checked)}
                color="primary"
              />
            }
            label="Enable email notifications for possible price-adjustment records? (The feature is not open yet!)"
          />
          <Box sx={{ mt: 2, position: 'relative' }}>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary"
              disabled={isSubmitting}
            >
              Submit
            </Button>
            {isSubmitting && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
          <Typography variant="caption">
            Disclaimer: By submitting your information, you acknowledge that the website (Profound Words) will not sell or distribute your email address or uploaded purchase data to third parties. However, the website makes no guarantees about data retention, security, or long-term storage. Users assume full responsibility for the data they upload. The website reserves the right to modify, delete, or manage user-submitted data without prior notice. The service is provided 'as is' without any explicit or implied warranties regarding data preservation, accuracy or protection.
          </Typography>
        </form>
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert 
          onClose={() => setOpenSnackbar(false)} 
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openGuide} onClose={() => setOpenGuide(false)} maxWidth="md" fullWidth>
        <DialogTitle>How to Get Cost$$ Receipts Data</DialogTitle>
        <DialogContent>
          <List>
            {guideSteps.map((step, index) => (
              <ListItem key={index}>
                <ListItemIcon>{step.icon}</ListItemIcon>
                <ListItemText 
                  primary={step.text}
                  secondary={step.downloadLink && (
                    <Button 
                      href={step.downloadLink} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      variant="outlined"
                      size="small"
                      sx={{ mt: 1 }}
                    >
                      Download cost$$.js
                    </Button>
                  )}
                />
                  {step.image && (
                  <Card sx={{ maxWidth: 345, ml: 2, cursor: 'pointer' }}>
                    <CardMedia
                      component="img"
                      image={step.image}
                      alt={`Step ${index + 1} illustration`}
                      onClick={() => window.open(step.image, '_blank')}
                    />
                  </Card>
                )}
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenGuide(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default UploadReceipts;